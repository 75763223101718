import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, siteUrl, image, twittercard, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            twittercard
          }
        }
      }
    `
  )

  return (
    <>
      {/* <!-- HTML Meta Tags --> */}
      <title>{title ? `${title} | ${site.siteMetadata?.title}` : site.siteMetadata?.title}</title>
      <meta name="description" content={description ? description : site.siteMetadata.description} />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={title ? `${title} | ${site.siteMetadata?.title}` : site.siteMetadata?.title} />
      <meta itemProp="description" content={description ? description : site.siteMetadata.description} />
      <meta itemProp="image" content={image ? image : site.siteMetadata?.image} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={siteUrl ? siteUrl : site.siteMetadata?.siteUrl } />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title ? `${title} | ${site.siteMetadata?.title}` : site.siteMetadata?.title} />
      <meta property="og:description" content={description ? description : site.siteMetadata.description} />
      <meta property="og:image" content={image ? image : site.siteMetadata?.image} />
      <meta property="og:site_name" content={title ? `${title} | ${site.siteMetadata?.title}` : site.siteMetadata?.title} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title ? `${title} | ${site.siteMetadata?.title}` : site.siteMetadata?.title} />
      <meta name="twitter:description" content={description ? description : site.siteMetadata.description} />
      <meta name="twitter:image" content={twittercard ? twittercard : site.siteMetadata?.twittercard}/>
      <meta name="twitter:creator" content={site.siteMetadata?.title || ``} />
      {children}
    </>
  )
}

export default Seo
